import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import MlIcon from "../components/mlIcon"
import NetworkIcon from "../images/chart-network.svg"
import BrainIcon from "../images/brain.svg"
import BrowserIcon from "../images/browser.svg"
import FingerprintIcon from "../images/fingerprint.svg"
import ListIcon from "../images/list-ul.svg"
import ChartIcon from "../images/chart-line.svg"

const icons = [
  { image: FingerprintIcon, alt: 'an icon showing a finger print' },
  { image: BrainIcon, alt: 'an icon showing a brain' },
  { image: NetworkIcon, alt: 'machine learning icon' },
  { image: BrowserIcon, alt: 'web browser icon' },
  { image: ListIcon, alt: 'list icon' },
  { image: ChartIcon, alt: 'chart icon' }
];

export default ({ data }) => {
  const {
    page: {
      childMarkdownRemark: { frontmatter },
    }
  } = data;

  return (
    <Layout meta={frontmatter.meta}>
      <section className="landing-banner">
        <div className="container">
          <div className="row">
            <div className="grid-2-col">
              <div className="landing-banner-content flex flex-ac">
                <div>
                  <h1 className="animated fadeInUp fast">{frontmatter.title}</h1>
                  <p className="lead animated fadeInUp">{frontmatter.subtitle}</p>
                  <p className="lead bold">Launching soon!</p>
                  <div className="animated fadeInUp delay-2s mt-2">
                    <Link to="/contact/" className="btn btn-navy">
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <Img
                  className='img-responsive'
                  fluid={frontmatter.image.childImageSharp.fluid}
                  alt={frontmatter.imageDescription}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="below-banner-pad">
        <div className="container">
          <div className="row">
            <div className="flex flex-jc">
              <div className="w-70 text-center">
                <MlIcon />
                <h2 className="bold">{frontmatter.contentHeading}</h2>
                <p className='lead'>{frontmatter.content}</p>
              </div>
            </div>
            <div className="grid-3-col narrow mt-2">
              {frontmatter.contentItems.map((c, i) => (
                <div key={c.title} className="product-feature">
                  <img src={icons[i % icons.length].image} alt={icons[i % icons.length].alt} aria-hidden="true" />
                  <h3>{c.title}</h3>
                  <p>{c.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query MarketingPageQuery($name: String!) {
    page: file(relativeDirectory: { eq: "marketing-pages" }, name: { eq: $name }) {
      childMarkdownRemark {
        frontmatter {
          title
          subtitle
          image {
            childImageSharp {
              fluid(quality: 95, maxWidth: 705) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageDescription
          contentHeading
          content
          contentItems {
            title
            content
          }
          meta {
            title
            description
            image {
              ...Layout_metaImage
            }
          }
        }
      }
    }
  }
`
